import { Injectable, Injector } from '@angular/core';
import { Booklet } from '@common/core/models';
import { IBitfApiResponse } from '@common/interfaces';
import { BitfApiService } from '@common/libs/bitforce/core/services/api/bitf-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookletsService extends BitfApiService {
  constructor(public injector: Injector) {
    super('booklets', injector);
  }

  loadFullBooklet(documentId: string): Observable<IBitfApiResponse<Booklet>> {
    return this.getById<Booklet>({
      id: documentId,
      populate: {
        bookletAttachments: {
          populate: {
            ape: { populate: '*' },
            landRegistry: { populate: '*' },
            deedOfOrigin: { populate: '*' },
            urbanPlanning: { populate: '*' },
            occupancyPermit: { populate: '*' },
            systems: { populate: '*' },
            boiler: { populate: '*' },
            cdu: { populate: '*' },
            latentDefectsInsurance: { populate: '*' },
            technicalReport: { populate: '*' },
            extra: { populate: '*' },
            extraContract: { populate: '*' },
            sellerIdentityCard: { populate: '*' },
            casaDocContract: { populate: '*' },
            apeSurveyorSurvey: { populate: '*' },
            apeSurveyorPictures: { populate: '*' },
          },
        },
        accounts: true,
        agent: { populate: '*' },
        technicalReportSurveyor: { populate: '*' },
        apeSurveyor: { populate: '*' },
        extraSurveyor: { populate: '*' },
        seller: { populate: '*' },
      },
    });
  }

  updateBookletAttachments(booklet: Booklet): Observable<IBitfApiResponse<Booklet>> {
    // implementation goes here
    return this.post({
      id: booklet.documentId,
      action: 'attachments',
      body: booklet,
      disableHideLoader: true,
    });
  }

  getBookletWithPassword(id: string, password: string): Observable<IBitfApiResponse<Booklet>> {
    return this.getById<Booklet>({
      id,
      relation: 'password',
      relationId: password,
    });
  }
}
